import React from 'react';

const Playground = () => {
  return (
    <div style={{ padding: '40px', textAlign: 'center', color: '#000' }}>
      <h2>Playground</h2>
      <p>This section will showcase games and tasks in the future.</p>
    </div>
  );
};

export default Playground;