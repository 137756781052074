import React from 'react';

const NFTs = () => {
  return (
    <div style={{ padding: '40px', textAlign: 'center', color: '#000' }}>
      <h2>NFT Collection</h2>
      <p>This section will showcase NFT collections in the future.</p>
    </div>
  );
};

export default NFTs;